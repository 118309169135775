.popular-blogs {
    margin: 40px 0 0;
    .popular-sep {
        margin-inline: 0;
    }
    .title {
        margin-bottom: 8px;
        text-align: start;
    }
    .subtitle {
        margin-bottom: 32px;
        margin-inline: 0;
    }
    .popular-blogs__container {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        width: 100%;
        .blog-holder {
            width: calc((100% - 16px) / 2);
            border-radius: 5px;
            .popular-content {
                padding: 0 10px;
            }
            &:hover {
                box-shadow: 0px 10px 20px rgba(23, 90, 220, 0.1);
                .popular-title {
                    color: $main-blue;
                }
            }
            @media screen and (min-width: 1024px) {
                width: calc((100% - 48px) / 4);
            }
        }
    }
}
.blog-popular {
    margin-bottom: 40px;
}
.popular-card {
    padding-bottom: 10px;
    .blog-type {
        font-family: 'Sofia-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 140%;
        color: $main-blue;
        margin-bottom: 8px;
        @media screen and (min-width: 768px) {
            font-size: 16px;
        }
    }
    .popular-image {
        width: 100%;
        height: 188px;
        border-radius: 5px;
        margin-bottom: 16px;
        img {
            border-radius: 5px;
        }
    }
    .popular-title {
        margin-bottom: 8px;
        font-family: 'Sofia-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        color: $medium-black;
        min-height: 44.8px;
        text-transform: capitalize;
        transition: all 0.3s ease-in-out;
        text-overflow: ellipsis;
        word-break: keep-all;
        @media screen and (min-width: 768px) {
            font-size: 25px;
            min-height: 70px;
        }
    }
    .popular-description {
        font-family: 'Sofia-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 22px;
        color: $dark-gray;
        min-height: 88px;
        max-height: 88px;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        @media screen and (min-width: 768px) {
            font-size: 16px;
        }
        em {
            font-size: 16px;
            color: $medium-grey;
            line-height: 140%;
        }
        p {
            display: inline;
        }
        p:not(:first-child) {
            margin-left: 5px;
        }
    }

    .date-author {
        font-family: 'Sofia-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        color: $dark-gray;
        gap: 6px;
        margin-top: 8px;
        @media screen and (min-width: 768px) {
            gap: 8px;
            font-size: 16px;
        }
        .author-name {
            @include regular-font;
            color: $medium-black;
            text-transform: capitalize;
            margin-bottom: 6px;
        }
        .date {
            text-transform: capitalize;
        }
    }
}
