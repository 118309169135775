.benefits {
    background-color: $main-blue;
    padding-block: 40px !important;
    @media screen and (min-width: 1280px) {
        padding-block: 80px !important;
    }
    .title {
        color: $white;
    }
    .centers {
        margin: 0 auto;
        margin-bottom: 12px;
    }
    .certified-container {
        display: flex;
        flex-wrap: wrap;
        gap: 35px;
        justify-content: center;
        @media screen and (min-width: 768px) {
            gap: 20px;
        }
        @media screen and (min-width: 1280px) {
            gap: 35px;
        }

        .certified {
            @media screen and (min-width: 768px) {
                width: calc((100% - 20px * 5) / 3);
            }
            @media screen and (min-width: 1024px) {
                width: calc((100% - 35px * 5) / 5);
            }

            @include flex-center;
            flex-direction: column;
            gap: 16px;
            .certified-image {
                height: 60px;
                @media screen and (min-width: 1280px) {
                    width: 100px;
                    height: 100px;
                }
            }
            p {
                @include regular-font;
                color: $white;
                text-align: center;
            }
        }
    }
}
