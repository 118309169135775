.listings {
    margin: auto;
    margin-top: 20px;
    padding-bottom: 60px !important;
    .listing-tops {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 12px;
        flex-direction: column;
        @media screen and (min-width: 768px) {
            align-items: center;
            flex-direction: row;
        }
        .listing-title {
            font-family: 'Sofia-Bold';
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            color: $main-dark-blue;
            font-size: 16px;
        }
        .sort-by {
            font-family: 'Sofia-Regular';
            font-style: normal;
            font-weight: 700;
            line-height: 140%;
            color: $light-black;
            @include flex-center;
            gap: 6px;
            justify-content: space-between;
            font-size: 16px;
            @media screen and (min-width: 768px) {
                justify-content: unset;
            }
            .sort-car-select {
                @include flex-center;
                @media screen and (min-width: 768px) {
                    min-width: 180px;
                }
                .ant-select-selection-item {
                    font-family: Sofia-Bold;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 140%;
                    color: $main-blue;
                }
                .ant-select-selector {
                    align-items: center;
                }
                .ant-select-arrow {
                    font-size: 14px;
                    font-weight: 500;
                    color: $main-blue;
                }
                .ant-select-dropdown {
                    z-index: 50 !important;
                    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
                        background-color: transparent !important;
                        color: $main-blue;
                    }
                }
            }
            .filter-car-select {
                @include flex-center;

                // @media screen and (min-width: 768px) {
                    min-width: 90px;
                // }

                .ant-select-selection-item {
                    font-family: Sofia-Bold;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 140%;
                    color: $main-blue;
                }

                .ant-select-selector {
                    align-items: center;
                }

                .ant-select-arrow {
                    font-size: 14px;
                    font-weight: 500;
                    color: $main-blue;
                }

                .ant-select-dropdown {
                    z-index: 50 !important;

                    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
                        background-color: transparent !important;
                        color: $main-blue;
                    }
                }
            }
        }
    }
    .listing-filter {
        display: flex;
        gap: 24px;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 480px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
        }
    }
    .listing-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .listing__holder {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            .card-holder {
                width: 100%;
                // height: 100%;
                @media screen and (min-width: 768px) {
                    width: calc((100% - 20px) / 2);
                }
                @media screen and (min-width: 1024px) {
                    width: calc((100% - 40px) / 3);
                }
                .card {
                    border-radius: 5px;
                }
            }
        }
    }
}
.listing-modal {
    width: 610px !important;
    .ant-modal-content {
        padding: 24px !important;
        .ant-modal-body {
            .left-align {
                margin-inline: 0;
            }
            .title {
                margin-bottom: 4px;
                font-size: 20px !important;
                text-align: start;
            }
            .subtitle {
                text-align: start;
                margin-bottom: 2px;
                display: inline-block;
            }
            .certification-form {
                margin-bottom: 0;
                .certification-top {
                    margin-bottom: 0;
                }
                .certification-bottom {
                    flex-wrap: wrap;
                    margin-bottom: 0;
                    .selects {
                        @media screen and (min-width: 768px) {
                            width: calc((100% - 24px) / 2);
                        }
                    }
                    .certification-input:nth-child(2) {
                        @media screen and (min-width: 768px) {
                            width: calc((100% - 24px) / 2) !important;
                        }
                    }
                }
                @media screen and (min-width: 768px) {
                    .bottom-btn {
                        display: inline-block;
                    }
                }
            }
        }
    }
}
