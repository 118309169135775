.button-wrapper {
    width: 100%;
    padding: 8px 16px;
    border-radius: 5px;
    @include regular-font;
    @include flex-center;
    gap: 10px;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.button-primary {
    background-color: $main-blue;
    color: $white;
    &:hover,
    &:active {
        background-color: $dark-blue;
    }
    &:disabled {
        background-color: $dark-gray !important;
        cursor: default;
    }
}

.button-secondary {
    background-color: $white;
    border: 1px solid $main-blue;
    color: $main-blue;
    &:hover {
        background-color: #dee6f7;
    }
    &:active {
        background-color: #bed8fe;
    }
    &:disabled {
        border: 1px solid $dark-gray;
        color: $dark-gray;
        background-color: $white !important;
        cursor: default;
    }
}

.button-ternary {
    background-color: $white;
    border: none;
    color: $main-blue;
    &:hover {
        background-color: #dee6f7;
    }
    &:active {
        background-color: #bed8fe;
    }
    &:disabled {
        color: $dark-gray;
        background-color: $white !important;
        cursor: default;
    }
}
