.auto-certified {
    padding-top: 80px !important;
    .centers {
        margin: 0 auto;
    }
    .certified-container {
        @include flex-center;
        flex-wrap: wrap;
        .certified {
            @media screen and (min-width: 768px) {
                width: 25%;
            }

            @include flex-center;
            flex-direction: column;
            margin-bottom: 60px;
            .certified-image {
                width: 120px;
                height: 120px;
            }
            p {
                @include regu-font;
                font-size: 20px;
                color: $black;
                text-align: center;
            }
        }
    }
}
