.loan-container {
    background-color: $main-blue;
    .loan {
        @include flex-center;
        flex-direction: column;
        padding-block: 40px !important;
        border-radius: 5px;
        @media screen and (min-width: 768px) {
            flex-direction: row;
            justify-content: space-between;
            padding-block: 30px !important;
        }
        @media screen and (min-width: 1024px) {
            padding-block: 50px !important;
        }
        .loan-left {
            @media screen and (min-width: 768px) and (max-width: 912px) {
                width: 48%;
            }
            @media screen and (min-width: 913px) {
                width: 60%;
            }
            .sec-seperator {
                margin: 0;
            }
            .title {
                color: #fff;
                margin: 8px 0 12px 0;
                text-align: start;
            }
            .subtitle {
                color: #fafafa;
                font-weight: 400;
                width: 90%;
                text-align: left;
                margin: 0;
                margin-bottom: 24px;
                @media screen and (min-width: 768px) {
                    width: 75%;
                }
            }
            .loan-btn {
                background: #091731;
                font-weight: 400;
                &:hover {
                    box-shadow: 0px 5px 10px rgba(9, 23, 49, 0.2);
                }
            }
        }
        .loan-image {
            display: none;
            @media screen and (min-width: 768px) {
                display: inline-block;
                width: 350px;
                height: 235px;
            }
        }
    }
}
