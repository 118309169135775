.finance-partner {
    background-color: $light-white;
    padding: 60px 0;
    .financing-partner {
        margin: 0 auto;
        @include flex-center;
        flex-direction: column;
        .subtitle {
            text-align: center;
            margin-top: 7px;
            margin-bottom: 25px;
        }
        .finance-partners {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 5px;
            img {
                object-fit: contain;
            }
            @media screen and (min-width: 1024px) {
                gap: 11px;
            }
            .partner-images {
                width: calc((100% - 33px) / 3);
                height: 90px;
                @media screen and (min-width: 1024px) {
                    width: calc((100% - 33px) / 4);
                }
            }
            .finance {
                height: unset;
                @media screen and (min-width: 1024px) {
                    height: 90px;
                    width: 277px;
                }
            }
            .insure {
                height: unset;
                @media screen and (min-width: 1024px) {
                    width: calc((100% - 33px) / 2);
                }
            }
        }
    }
}
