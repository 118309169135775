.testimonial {
    margin: 0 auto;
    @include flex-center;
    flex-direction: column;
    padding: 60px 0;
    .title {
        margin-bottom: 8px;
    }
    .subtitle {
        text-align: center;
        margin-bottom: 32px;
    }
    .testimonial__container {
        @include flex-center;
        flex-wrap: wrap;
        gap: 24px;
        width: 96%;
        margin: 0 auto;
        .testimonial-swiper {
            .swiper-button-prev {
                display: flex !important;
                align-items: center;
                color: #454545;
                background-color: $extra-white;
                border: 1px solid $main-dark-blue;
                border-radius: 20px;
                font-size: 10px !important;
                height: 42px;
                width: 42px;
                box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
                left: 0px;
                z-index: 10;
            }

            .swiper-button-next {
                display: flex !important;
                align-items: center;
                color: #454545;
                background-color: $extra-white;
                border: 1px solid $main-dark-blue;
                border-radius: 20px;
                font-size: 10px !important;
                height: 42px;
                width: 42px;
                box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
            }
            .swiper-button-next:after {
                font-size: 16px;
                font-weight: 700;
            }
            .swiper-button-prev:after {
                display: block;
                font-size: 16px;
                font-weight: 700;
                color: $main-dark-blue;
            }
        }
    }
}
.testimonial-card {
    background-color: $light-white;
    border-radius: 5px;
    padding: 24px;
    @include flex-center;
    flex-direction: column;
    justify-content: center;
    transform: scale(1);
    transition: all 0.5s ease-out;
    text-align: center;
    &:hover {
        transform: scale(1.1);
    }

    .testiminial-image {
        width: 110px;
        height: 106px;
        margin-bottom: 16px;
        background: url(../../../public/images/testimonial-ventor.svg) no-repeat;
        background-size: 100px 100px;
        .image {
            width: 90px;
            height: 90px;
            border-radius: 50%;
            overflow: hidden;
            img {
                object-fit: cover;
            }
        }
    }
    .testi-name {
        font-family: 'Sofia-Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: $medium-black;
        margin-bottom: 6px;
        @media screen and (min-width: 1024px) {
            font-size: 20px;
            margin-bottom: 8px;
        }
    }
    .job-title {
        font-family: 'Sofia-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        color: $medium-black;
        margin-bottom: 16px;
        .job_title {
            text-transform: uppercase;
        }
        .company_name {
            text-transform: capitalize;
        }
        @media screen and (min-width: 1024px) {
            font-size: 16px;
            margin-bottom: 30px;
        }
    }
    .quote-image {
        height: 32px;
        width: 32px;
    }
    .desc {
        @include regular-font;
        line-height: 24px;
        color: $dark-gray;
        margin-top: 20px;
        @media screen and (min-width: 1024px) {
            margin-top: 24px;
        }
    }
}
