.categories-card {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .image-container {
        height: 322px;
        border-radius: 5px;
        overflow: hidden;
        img {
            width: 100%;
            transform: scale(1);
            transition: transform 0.5s ease-out;
        }
    }
    &:hover {
        .image-container {
            img {
                transform: scale(1.2);
            }
        }
        .category-content {
            .title {
                text-decoration: underline;
            }
        }
    }

    .category-content {
        margin-top: 16px;
        .title {
            border-bottom: 2px solid transparent;
        }
        span {
            font-family: 'Sofia-Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
            color: $main-blue;
        }

        .desc {
            @include regular-font;
            color: $light-black;
            margin-bottom: 8px;
            margin-top: 8px;
            b {
                color: $medium-black;
            }
        }
        .date-author {
            @include flex-center;
            font-family: 'Sofia-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 140%;
            color: $dark-gray;
            gap: 7px;
            @media screen and (min-width: 768px) {
                font-size: 16px;
            }
            .dot {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: $main-blue;
            }
            .date {
                text-transform: capitalize;
            }
        }
    }
}
.ant-pagination {
    width: 50%;
    margin-inline: auto !important;
    margin-bottom: 80px !important;
    @include flex-center;
    justify-content: center;
    margin-top: 48px !important;
    @media screen and (min-width: 768px) {
        gap: 16px;
    }
    li {
        min-width: 38px !important ;
        min-height: 38px;
        width: 38px;
        height: 38px !important;
        border-radius: 5px;
        overflow: hidden;
        @media screen and (min-width: 600px) {
            min-width: 42px;
            min-height: 42px;
            width: 42px;
            height: 42px;
        }
        &:hover {
            box-shadow: 0px 5px 10px rgba(15, 17, 17, 0.15);
            a {
                color: $main-blue;
            }
        }
    }
    .ant-pagination-prev {
        border: 1px solid #e0e0e0;
        display: grid;
        place-items: center;
        a {
            width: 100%;
        }
        &:hover {
            a {
                border: 1px solid #e0e0e0;
                color: $main-blue;
            }
        }
        .ant-pagination-item-link {
            border: none;
            border-radius: 5px;
            .anticon {
                font-size: 16px;
            }
            &:hover {
                color: $main-blue;
            }
        }
    }
    .ant-pagination-next {
        border: 1px solid #e0e0e0;
        display: grid;
        place-items: center;
        a {
            width: 100%;
        }
        &:hover {
            a {
                border: 1px solid #e0e0e0;
                color: $main-blue;
            }
        }
        .ant-pagination-item-link {
            border: none;
            border-radius: 5px;
            .anticon {
                font-size: 16px;
            }
            &:hover {
                color: $main-blue;
            }
        }
    }
    .ant-pagination-item {
        background: #ffffff;
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        display: grid;
        place-items: center;
        @include regular-font;
        margin-right: 0;
        a {
            color: $dark-gray !important;
        }
    }
    .ant-pagination-item-active {
        box-shadow: 0px 5px 10px rgba(15, 17, 17, 0.15);
        border: none;
        a {
            color: $main-blue;
        }
    }
    .ant-pagination-disabled {
        display: none;
    }

    .ant-pagination-jump-next {
        background: #ffffff;
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        @include flex-center;
        justify-content: center;
        a {
            width: 100%;
        }
    }
    .ant-pagination-jump-prev {
        background: #ffffff;
        border: 1px solid #e0e0e0;
        border-radius: 5px;

        @include flex-center;
        justify-content: start;
        a {
            width: 100%;
        }
    }
    .ant-pagination-options {
        display: none;
        .ant-pagination-options-size-changer {
            .ant-select-selector {
                display: none;
            }
        }
    }
}
