.nav-with-search {
    @include flex-center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 101;
    background-color: $white;
    width: 100%;
    box-shadow: 0px 10px 20px rgba(50, 50, 50, 0.05);
    height: 60px;
    @media screen and (min-width: 912px) {
        height: 80px;
    }
    .padding-container {
        @include flex-center;
        justify-content: space-between;
        width: 100%;
    }
    .language-container {
        padding-right: 0;
    }
    .logo-container {
        top: 0;
        width: 88px;
        height: 88px;
        border-radius: 50%;
        left: 15px;
        background-color: $white;
        margin-top: -20px;

        @media screen and (min-width: 768px) {
            width: 100px;
            height: 80px;
            top: 0;
            left: 30px;
        }
        @media screen and (min-width: 1024px) {
            width: 125px;
            height: 101px;
        }
        @media screen and (min-width: 1200px) {
            height: 142px;
            width: 142px;
            margin-top: -42px;
            left: 60px;
        }
        @media screen and (min-width: 1440px) {
            left: 120px;
        }
        img {
            margin-top: 6px;
            @media screen and (min-width: 768px) {
                margin-top: 10px;
            }
        }
    }
    .navbar-middle {
        position: relative;
        li {
            @include regular-font;
            color: #595959;
            &:hover {
                color: $main-blue;
                svg {
                    color: $main-blue;
                }
            }
            a {
                text-decoration: none;
                font-family: Sofia-Regular;
                &:hover {
                    color: $main-blue;
                }
            }
            .navbar-middle-link {
                color: $light-black;
                &:after {
                    display: block;
                    content: '';
                    border-bottom: solid 2px $main-blue;
                    transform: scaleX(0);
                    transition: transform 250ms ease-in-out;
                }
                &:hover:after {
                    transform: scaleX(1);
                    color: $main-blue;
                }
            }
            .navbar-middle-link-active {
                color: $main-blue;
                &:after {
                    display: block;
                    content: '';
                    border-bottom: solid 2px $main-blue;
                }
            }
            .categories-link {
                @include flex-center;
                gap: 8px;
                &:hover {
                    svg {
                        color: $main-blue;
                        polyline {
                            stroke: $main-blue;
                        }
                    }
                }
            }
            .categories-item {
                @include flex-center;
                gap: 7px;
                border-bottom: 2px solid transparent;
                cursor: pointer;
                svg {
                    margin-top: 5px;
                }
                &:hover {
                    .caret-icon {
                        svg {
                            polyline {
                                stroke: $main-blue;
                            }
                        }
                    }
                }
            }
            .categories-item.active {
                color: $main-blue;
                border-bottom: 2px solid $main-blue;
                .caret-icon {
                    svg {
                        polyline {
                            stroke: $main-blue;
                        }
                    }
                }
            }
        }
        .hamburger {
            display: inline-block;
            @media screen and (min-width: 912px) {
                display: none;
            }
        }
        .navbar-middle-links-mobile {
            list-style: none;
            align-items: center;
            gap: 32px;
            margin: 0;
            position: absolute;
            right: 0;
            top: 50px;
            background-color: $white;
            padding: 20px;
            width: 264px;
            box-shadow: 0px 5px 10px rgba(15, 17, 17, 0.1);
            border-radius: 5px;
            li {
                margin-block: 16px;
                .navbar-middle-link {
                    &:after {
                        display: block;
                        content: '';
                        border-bottom: solid 1px $medium-grey;
                        transform: scaleX(1);
                        margin-top: 16px;
                        transition: transform 250ms ease-in-out;
                    }
                    &:hover:after {
                        transform: scaleX(1);

                        color: $main-blue;
                    }
                }
                .navbar-middle-link-active {
                    &:after {
                        margin-top: 16px;
                    }
                }
            }
            li:last-child,
            li:first-child {
                margin-block: 0;
            }
        }
        .navbar-middle-links {
            list-style: none;
            align-items: center;
            gap: 32px;
            margin: 0;
            display: none;
            @media screen and (min-width: 912px) {
                display: flex;
            }
        }
    }
}
.border-light {
    width: 28px;
    height: 1px;
    background-color: #e0e0e0;
    transform: rotate(90deg);
    flex: none;
    flex-grow: 0;
}
