.brands-name {
    width: 94%;
    margin: 0 auto;
    @include flex-center;
    flex-direction: column;
    padding: 60px 0;
    @media screen and (min-width: 1440px) {
        max-width: 1200px;
    }
    .subtitle {
        text-align: center;
        width: 90%;
        margin-bottom: 32px;
        @media screen and (min-width: 768px) {
            width: 70%;
        }
        @media screen and (min-width: 1024px) {
            width: 51%;
        }
    }
    .brand-name-items {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
        @media screen and (min-width: 768px) {
            width: 79%;
            gap: 18px;
        }
        @media screen and (min-width: 1440px) {
            max-width: 1200px;
            width: 100%;
        }
        .brand-image {
            width: 50px;
            height: 50px;
            margin-left: 12px;
            @media screen and (min-width: 768px) {
                width: 80px;
                height: 80px;
            }
            img {
                object-fit: contain;
            }
        }
        .brand-name {
            @include brand-name;
        }
    }
}
