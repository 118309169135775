.loading-btn {
    display: flex !important;
    border: 1px solid $dark-gray;
    &:disabled {
        border: 1px solid $dark-gray;
        background-color: $dark-gray;
        cursor: no-drop;
    }
    &:disabled:hover {
        border: 1px solid $dark-gray;
        background-color: $dark-gray;
        cursor: no-drop;
    }
}
.rotate {
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
