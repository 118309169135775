.fileupload-container {
    position: relative;
    margin-bottom: 2rem;
    border: 1px dashed #6690e4;
    border-radius: 4px;
    transition: all 1s ease;
    background-color: $input-background;
    .drag-n-drop-input {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        opacity: 0;
        left: 0;
        background-color: $input-background;
        cursor: pointer;
    }
    .image-preview {
        width: 100%;
        position: absolute;
        height: 100px;
        cursor: pointer;
        bottom: 0;
        @include flex-center;
        justify-content: space-between;
        img {
            width: 100%;
        }
        :hover {
            border: 1px solid red;
        }
    }
}
.image-previews {
    display: none;
}
.dragger {
    border: 1px solid #6690e4;
}

.drop-file-preview {
    width: 250px;
    word-wrap: break-word;
    position: absolute;
    top: 0;
    left: 10px;
    height: 100%;
    overflow: scroll;
    padding: 0 1.3em 0 0;

    cursor: pointer;
    .drop-file-preview-item {
        position: relative;
        @include flex-center;
        margin-bottom: 0.8rem;
        margin-left: 10px;
        background-color: $input-background;
        border-radius: 4px;
        .ant-image-preview-img {
            width: 50% !important;
        }
        .drop-file-preview-item-info {
            margin-left: 0.5rem;
            margin-right: 1rem;
        }
        .drop-file-delete {
            position: absolute;
            cursor: pointer;
            top: 0;
            right: 0;
        }
    }
}
.ant-image-preview-img-wrapper {
    padding: 3rem;
    object-fit: contain;

    .ant-image-preview-img {
        object-fit: contain;
    }
}
