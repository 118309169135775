.menu-btn {
    width: 88px;
    height: 44px;
    background-color: $white;
    @include flex-center;
    padding: 12px;
    gap: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    cursor: pointer;
    .seperator {
        background-color: #595959;
        width: 1px;
        display: inline-block;
        height: 20px;
    }
}
