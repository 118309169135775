.categories-page {
    margin-bottom: 80px;
    .category-seperator {
        margin: 0 0 8px;
    }
    .title {
        text-align: start;
    }
    .categories-container {
        margin: 0 auto;
        margin-top: 37px;

        @media screen and (min-width: 1024px) {
            width: 71.81%;
            padding: 0;
        }
        @media screen and (min-width: 1200px) {
            width: 55.14%;
        }
        .category-cards {
            display: flex;
            flex-direction: column;
            gap: 40px;
            margin-top: 24px;
        }
    }
}
