.faq-page {
    .faqs {
        @include flex-center;
        flex-direction: column;
        min-width: 55%;
        width: 100%;
        margin: 0 auto;
        margin-top: 24px;
        margin-bottom: 80px;
        padding: 0 15px;
        @media screen and (min-width: 768px) {
            padding: 0 30px;
            margin-top: 60px;
        }
        @media screen and (min-width: 1024px) {
            min-width: 55%;
            width: 55%;
        }
        .subtitle {
            text-align: center;
        }
        .questionnaire-container {
            width: 100%;
            padding: 0;
            .questionnaire-image {
                display: none;
            }
            .questionnaire {
                width: 100%;
                align-items: center;
                justify-content: center;
                padding: 0;
                margin: 0;
                .ant-collapse {
                    background-color: $white;
                }
                .question {
                    display: none;
                }
                .didnt-finds-questions {
                    @include flex-center;
                    justify-content: space-between;
                    width: 100%;
                    margin-top: 32px;
                    border-radius: 5px;
                    @media screen and (min-width: 768px) {
                        padding: 16px 24px;
                        background-color: $light-white;
                    }
                    p {
                        @include regular-font;
                        line-height: 28px;
                        color: #000000;
                        @media screen and (max-width: 767px) {
                            width: 50%;
                        }
                    }
                }
            }
        }
    }
}
