.process-container {
    background-color: $light-white;
    padding-block: 40px;
    @media screen and (max-width: 767px) {
        overflow-x: auto;
    }
    .process {
        width: 90%;
        margin: 0 auto;
        @include flex-center;
        flex-direction: column;
        justify-content: center;
        overflow-x: auto;
        @media screen and (min-width: 768px) {
            width: 90%;
            overflow-x: hidden;
        }

        @media screen and (min-width: 1440px) {
            width: 70%;
        }
        .why-sep {
            height: 100%;
        }
        .process-headers {
            margin-top: 16px;
        }

        .buy {
            font-family: 'Sofia-Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
            text-align: center;
            color: $main-dark-blue;
        }
        .sell {
            font-weight: 700;
            color: $dark-gray;
            font-size: 16px;
            line-height: 140%;
            font-family: 'Sofia-Bold';
            font-style: normal;
            font-weight: 400;
            text-align: center;
        }

        .show {
            display: flex;
            justify-content: center;
            width: 100%;
            overflow-x: auto;
            transition: all 0.5s ease-in-out;
        }
        .show::-webkit-scrollbar {
            display: none;
        }
        .hide {
            display: none;
            width: 100%;
            transition: all 0.5s ease-in-out;
        }
    }
}

.buy-pane-desktop::-webkit-scrollbar {
    display: none;
}
.buy-pane-desktop {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 50px;
    width: 100%;
    overflow: auto;
    min-height: 174px;

    @media screen and (min-width: 1200px) {
        width: 80%;
    }
    @media screen and (min-width: 1441px) {
        max-width: 1200px;
        margin-inline: auto;
    }
    img {
        object-fit: contain;
    }
    .down-image {
        width: 152px;
        position: absolute;
        left: 128px;
        @media screen and (min-width: 768px) {
            width: 138px;
            left: 169px;
        }
        @media screen and (min-width: 1024px) {
            width: 213px;
            left: 185px;
        }
    }
    .up-image {
        width: 152px;
        width: 152px;
        position: absolute;
        // right: -168px;
        left: 354px;
        top: 15px;
        @media screen and (min-width: 768px) {
            width: 138px;
            right: 145px;
            left: unset;
        }
        @media screen and (min-width: 1024px) {
            width: 213px;
            right: 183px;
        }
    }
}
.process-unit {
    min-width: 214px;
    max-width: 214px;
    @include flex-center;
    flex-direction: column;
    gap: 12px;
    @media screen and (min-width: 768px) {
        min-width: 214px;
    }
    .process-image {
        width: 48px;
        height: 40px;
    }
    .process-title {
        font-family: 'Sofia-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        text-align: center;
        color: $main-dark-blue;
        text-transform: capitalize;
    }
    .process-desc {
        text-align: center;
        @include regular-font;
        line-height: 30px;
        text-align: center;
        color: #8b8b8b;
    }
}
.process-comp {
    @include flex-center;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    @media screen and (min-width: 768px) {
        width: 48%;
        align-items: flex-start;
    }
    @media screen and (min-width: 1024px) {
        width: calc(((100% - 72px) / 4));
        max-width: fit-content;
    }
    @media screen and (min-width: 1024px) {
        width: calc(((100% - 120px) / 4));
        max-width: fit-content;
    }

    .process-comp-image {
        width: 55px;
        height: 55px;
    }
    .whiteBg {
        background-color: $white;
        border-radius: 5px;
        padding: 5px;
    }
    .process-comp-desc {
        font-family: Sofia-bold;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        color: #ffffff;
    }
    .process-comp-descs {
        @include regular-font;
        color: #ffffff;
    }
}

.process-background {
    background: url('../../../public/images/gaadi1.webp') no-repeat;
    background-size: cover;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .process-comp-container {
        width: 100%;
        margin: auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 50px;
        margin-bottom: 30px;
        gap: 20px;
        text-align: center;
        @media screen and (min-width: 768px) {
            gap: 20px;
            text-align: left;
        }
        @media screen and (min-width: 1024px) {
            gap: 24px;
            flex-direction: row;
        }
        @media screen and (min-width: 1500px) {
            gap: 40px;
        }
    }
}
.process-background.margin {
    margin: 40px 0 20px;
    padding: 50px 0;
    .center {
        margin: 0 auto;
        margin-bottom: 12px;
    }
    h2 {
        font-family: Sofia-Bold;
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 140%;
        text-align: center;
        color: $white;
    }
    .process-comp-container {
        gap: 0;
        flex-wrap: nowrap;
        .process-comp {
            justify-content: center;
            align-items: center;
            @media screen and (min-width: 1024px) {
                width: calc(100% / 7);
            }
            .process-comp-descs {
                font-size: 13px;
                text-align: center;
            }
        }
    }
}

.process-switch {
    display: flex;
    height: 48px;
    background: $extra-white;
    box-shadow: inset 0px 1px 11px rgba(0, 0, 0, 0.16);
    border-radius: 30px;
    padding: 5px;
    cursor: pointer;

    .process-switch-toggle {
        padding: 0 16px;
        height: 100%;
        color: $light-black;
        @include regular-font;
        @include flex-center;
        justify-content: center;
        border-radius: 30px;
    }

    .process-switch-toggle-checked {
        font-family: 'Sofia-Bold';
        background-color: $main-blue;
        color: $white;
        transition: all 0.2s ease-in;
    }
}
