.virtual-garage {
    .virtual-sep {
        margin-inline: 0;
    }
    .favourite-notfound {
        @include flex-center;
        justify-content: center;
        padding-top: 22px;
        text-align: center;
        .fav-container {
            text-align: center;
            position: relative;
            .title {
                text-align: center;
            }
            .subtitle {
                margin-top: 20px;
                width: 100%;
                @include regular-font;
                text-align: center;
                color: #595959;
                flex: none;
                order: 0;
                flex-grow: 0;
                @media screen and (min-width: 768px) {
                    height: 44px;
                    width: 400px;
                }
            }
            img {
                width: 200px;
            }
            .explore-btn {
                font-weight: 400;
                width: 200px;
                height: 50px;
                margin-bottom: 100px;
                margin-inline: auto;
            }
        }
    }
    .virtual-title {
        text-align: start;
        margin-bottom: 7px;
        @media screen and (min-width: 1024px) {
        }
    }

    .subtitle {
        text-align: start;
        margin-inline: 0;
        margin-bottom: 24px;
        @media screen and (min-width: 1024px) {
            // text-align: center;
            margin-bottom: 32px;
            // margin-inline: auto;
        }
    }
    .virtual-garage__holder {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 80px;
        .card-holder {
            width: 100%;
            @media screen and (min-width: 912px) {
                width: calc((100% - 20px) / 2);
            }
            .border {
                border: none;
            }
        }
    }
}
