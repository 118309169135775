table {
    font-family: 'Sofia-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    .ant-table-thead {
      tr {
        th {
          background: #F5F8FF !important;
          height: 54px;
          font-family: 'Sofia-Bold';
          font-weight: 700;
          border-radius: 5px;
        }
      }
    }
    .ant-table-tbody {
      tr {
        td {
            color: #454545;
            background-color: #F2F2F2;
            height: 82px;
          .profile {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 16px;
            &-avatar {
              width: 50px;
              height: 50px;
              & > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
              }
            }
            &-name {
              margin: 0;
            }
          }
        }
      }
      tr:nth-child(odd) {
        td {
            background-color: #FAFAFA;
        }
      }
    }
  }

  .ant-pagination {
    margin: 16px 0 0 0 !important;
  }

  .ant-pagination-total-text {
    width: auto !important;
  }

  .ant-table-pagination {
      width: 100%;
      justify-content: center !important;
  }