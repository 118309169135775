.contact-us {
    .contact-top {
        margin: 0 auto;
        @include flex-center;
        flex-direction: column;
        justify-content: space-between;
        gap: 60px;
        flex: 1 1;
        margin-top: 60px;
        margin-bottom: 90px;
        @media screen and (min-width: 1024px) {
            flex-direction: row;
        }
        .contact-form {
            display: flex;
            flex-direction: column;
            width: 100%;
            @media screen and (min-width: 1024px) {
                width: 45%;
            }
            @media screen and (min-width: 1440px) {
                width: 50.75%;
            }
            .contact-sep {
                margin-inline: 0;
            }
            .title {
                text-align: left;
            }
            .subtitle {
                margin-bottom: 32px;
                width: 100%;
                text-align: left;
            }
            .form {
                .form-container {
                    display: flex;
                    flex-direction: column;
                    gap: 24px;
                    margin-bottom: 24px;

                    .contact-input {
                        @include flex-center;
                        gap: 10px;
                        padding: 13px 12px;
                        border: 1px solid $medium-grey;
                        border-radius: 5px;
                        background: #f5f7ff;
                        height: 50px;
                        position: relative;
                        .icon {
                            margin-top: 3px;
                        }
                        input {
                            outline: none;
                            background-color: $extra-white;
                            width: 100%;
                            border: none;
                            @include input-font;
                        }
                        input::placeholder {
                            @include input-placeholder;
                            background-color: $extra-white;
                        }
                        input:-webkit-autofill {
                            -webkit-text-fill-color: $light-black !important;
                        }
                    }
                    textarea {
                        outline: none;
                        background-color: $extra-white;
                        border: 1px solid $medium-grey;
                        border-radius: 5px;
                        width: 100%;
                        @include input-font;
                        padding: 13px 12px;
                        height: 116px;
                    }
                    textarea::placeholder {
                        @include input-placeholder;
                        background-color: $extra-white;
                    }
                    p {
                        @include regular-font;
                        color: $dark-gray;
                        margin-top: -16px;
                    }
                    .error-message {
                        position: absolute;
                        bottom: -23px;
                        left: 0;
                    }
                }
            }
            .bottom-btn:disabled {
                background-color: $dark-gray;
                cursor: no-drop;
            }
            .send {
                min-width: unset;
            }
        }
        .contact-image {
            position: relative;
            width: 100%;
            @media screen and (min-width: 1024px) {
                height: 459px;
                width: calc(55% - 60px);
            }
            @media screen and (min-width: 1440px) {
                height: 460px;
                width: calc(49% - 60px);
            }
            .contact-vector {
                height: 100%;
                width: 100%;
                display: none;
                @media screen and (min-width: 1024px) {
                    display: inline-block;
                }
                img {
                    object-fit: contain;
                }
            }

            .contact-infos {
                width: 100%;
                background-image: url(../../../public/images/contact.svg);
                background-repeat: no-repeat;
                background-size: 460px auto;
                padding: 32px;
                border-radius: 5px;
                @media screen and (min-width: 1024px) {
                    position: absolute;
                    top: 12px;
                    left: 25px;
                    width: 432px;
                }
                @media screen and (min-width: 1200px) {
                    top: 30px;
                    left: 52px;
                }
                .seperator {
                    border: 1px solid #ffffff;
                    height: 0;
                    width: 100px;
                    margin-bottom: 8px;
                    background-color: #fff;
                }
                .contact-title {
                    @include bold-font;
                    font-size: 25px;
                    color: $white;
                    margin-bottom: 12px;
                    @media screen and (min-width: 1200px) {
                        margin-bottom: 25px;
                    }
                }
                .contact-info {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    margin-bottom: 15px;
                    .contact {
                        @include regular-font;
                        font-size: 20px;
                        color: $white;
                    }
                }
            }
        }
    }
    .map-container {
        width: 100%;
        height: 422px;
        iframe {
            width: 100%;
            height: 100%;
            border: 0;
        }
    }
}
