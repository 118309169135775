.blog-type {
    display: flex;
    gap: 16px;
    overflow-x: auto;
    .tag {
        height: 30px;
        min-width: fit-content;
        background: $grey;
        border-radius: 5px;
        padding: 6px 10px;
        font-family: 'Sofia-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 140%;
        color: $medium-black;
    }
}
