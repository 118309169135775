.teamDrawer {
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // position: sticky;
        // top: 0;
        z-index: 1001;
        height: 36px;
        background-color: #FFF;
    }
    &__close {
        width: 24px;
        height: 24px;
        border-radius: 0.2rem;
        & > svg {
            width: 100%;
            height: 100%;
            color: #454545;
            & > line {
                stroke-width: 1.6rem;
            }
        }
        &:hover {
            cursor: pointer;
            & > svg {
                color: black;
            }
        }
    }
    &__secSeperator {
        margin-bottom: 0;
    }
    &__secSeperatorTop {
        margin-bottom: 0;
        margin-left: 0;
        // position: sticky;
        // top: 0;
    }
    &__title {
        font-family: 'Sofia-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 140%;
        color: #091731;
    }
    &__line {
        border-color: #f2f2f2 !important;
        margin: 16px 0 !important;
    }
    &__brand {
        background-color: #f5f8ff;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        &__photo {
            width: 80px;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            & > img {
                // width: 100%;
                // height: 100%;
                width: 60px;
                height: 60px;
                object-fit: cover;
            }
        }
        & > h4 {
            font-family: 'Sofia-Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 140%;
            color: #091731;
        }
    }
    &__profile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        margin-bottom: 24px;
        &__photo {
            width: 120px;
            height: 120px;
            border-radius: 8px;
            & > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 8px;
            }
        }
        &__info {
            flex-grow: 1;
            & > h4 {
                font-family: 'Sofia-Bold';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 140%;
                color: #091731;
                margin-bottom: 8px;
            }
            & > p {
                font-family: 'Sofia-Regular';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
                color: #595959;
                margin-bottom: 24px;
            }
        }
        &__buttons {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;
        }
    }
    &__callButton {
        margin-bottom: 24px;
    }
    &__contact {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        & > p {
            font-family: 'Sofia-Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
            color: #091731;
        }
        & > span {
            font-family: 'Sofia-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            color: #595959;
        }
    }
    &__button {
        margin-top: 24px;
    }
}

.ant-drawer-content-wrapper {
    @media screen and (max-width: 470px) {
        width: 100% !important;
    }
}
.ant-drawer-content {
    border-radius: 10px 0 0 10px;
}
.ant-drawer-header {
    display: none !important;
}
.ant-drawer-body {
    padding-top: 32px !important;
}
