@mixin regu-font {
    font-family: Sofia-Regular;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}
@mixin regular-font {
    @include regu-font;
    font-size: 16px;
}
@mixin bold-font {
    font-family: Sofia-Bold;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

@mixin brand-name {
    font-family: Sofia-Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 140%;
    color: $main-dark-blue;
    text-align: center;
}
@mixin input-font {
    @include regular-font;
    color: $light-black;
    cursor: text;
}
@mixin input-placeholder {
    @include regular-font;
    color: $medium-grey;
    text-transform: capitalize;
}

@mixin flex-center {
    display: flex;
    align-items: center;
}
