.car-insurance {
    .sub-title {
        margin-bottom: 14px;
        margin-top: 12px;
        text-align: center;
    }
    .phone-input {
        margin-top: 23px;
        align-content: center;
        @include input-font;
        padding-left: 10px;
        padding-top: 10px;
        input {
            background-color: transparent;
            width: 82%;
            margin-top: -5px;
            margin-left: 8px;
            &::placeholder {
                color: $medium-grey;
            }
        }
    }
    .term-of-use {
        margin-top: 10px;
    }
    .callback-btn {
        margin-top: 12px;
        .loading-btn {
            background-color: $dark-gray;
            cursor: no-drop;
            border: 1px solid $dark-gray;
        }
    }
}
.overflow {
    overflow: visible !important;
}
