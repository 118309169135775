.press {
    padding-block: 60px !important;
    background-color: $white;
    @media screen and (min-width: 768px) {
        .center {
            margin-inline: auto;
        }
        .title {
            text-align: center;
            margin-bottom: 8px;
        }
        .subtitle {
            text-align: center;
        }
    }
    .image-wrapper {
        @include flex-center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 50px;
        flex-direction: column;
        margin-top: 30px;
        @media screen and (min-width: 768px) {
            flex-direction: row;
            gap: 0;
            margin-top: 30px;
        }
        .image-container {
            width: 50%;
            @media screen and (min-width: 768px) {
                width: 18%;
            }
        }
    }
    .top {
        justify-content: center;
        margin-bottom: 20px;
        gap: 20px;

        @media screen and (min-width: 1024px) {
            gap: 200px;
            margin-bottom: 30px;
        }
    }
    .bottom {
        gap: 50px;
        @media screen and (min-width: 1200px) {
            gap: 170px;
        }
        .image-container {
            width: 50%;
            @media screen and (min-width: 768px) {
                width: 15%;
                max-height: 58px;
            }
        }
    }
}
