.summary-card {
    padding: 12px;
    background-color: $light-white;
    @include flex-center;

    justify-content: space-between;
    .summary-icon-title {
        @include flex-center;
        gap: 11px;
        .summary-icons {
            width: 24px;
            height: 24px;
        }
        .summary-title {
            @include regular-font;
            color: #595959;
            text-transform: capitalize;
        }
    }
    .summary-value {
        font-family: 'Sofia-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        color: $black;
        @include flex-center;
        p {
            color: $main-blue;
            text-transform: capitalize;
            cursor: pointer;
        }
        p:hover {
            color: $dark-blue;
        }
        .photo-preview {
            display: none;
        }
    }
}
