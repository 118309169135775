.list-your-car {
    margin-bottom: 80px;
    .listcar-sep {
        margin-inline: 0;
    }

    .title {
        font-family: 'Sofia-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 25px !important;
        line-height: 140%;
        color: $main-dark-blue;
        text-align: start;
    }
    .section-title {
        font-family: 'Sofia-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        color: $main-dark-blue;
        margin-bottom: 16px;
        @media screen and (min-width: 1024px) {
            font-size: 20px;
        }
    }
    .basic-details {
        margin-block: 24px;

        @media screen and (min-width: 1024px) {
            margin-top: 0;
        }
        .basic-details-top {
            .selects-container {
                width: 100%;
                .selects-prefixicon {
                    width: 26px;
                    height: 26px;
                    min-width: 26px;
                }
            }

            .basic-details-bottom {
                @include flex-center;
                flex-wrap: wrap;
                column-gap: 15px;
                row-gap: 25px;
                margin-block: 25px;
                .selects-container {
                    @media screen and (min-width: 1024px) {
                        width: calc((100% - 15px) / 2);
                    }
                }
            }
            .sell-input {
                border: 1px solid #b3b3b3;
                border-radius: 5px !important;
                height: 48px !important;
                @include flex-center;
                padding: 0 13px;
                background-color: $extra-white;
                gap: 5px;
                width: 100%;
                @media screen and (min-width: 1024px) {
                    width: calc((100% - 24px) / 2);
                }
                .icon {
                    width: 23px;
                    height: 21px;
                }
                input {
                    border-radius: 5px !important;
                    width: 100%;
                    height: 100%;
                    background-color: $extra-white;
                    @include regular-font;
                    color: #b3b3b3;
                }
                input::placeholder {
                    padding-top: 12px;
                    font-family: 'Sofia-Regular';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 140%;
                    color: #b3b3b3;
                    background-color: $extra-white;
                }
            }
        }

        textarea {
            outline: none;
            border-radius: 5px;
            padding: 13px 12px;
            border: 1px solid $medium-grey;
            width: 100%;
            @include input-font;
            background-color: $extra-white;
        }
        textarea::placeholder {
            @include input-placeholder;
        }
    }
    .expected-price {
        width: 448px;
        margin-top: 20px;
        margin-bottom: 20px;
        input {
            padding: 14px 12px;
            width: 100%;
            height: 50px;
            border: 1px solid $medium-grey;
            border-radius: 5px;
            font-family: 'Sofia-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            color: $dark-gray;
            background-color: $extra-white;
            @media screen and (min-width: 1024px) {
                width: 290px;
            }
        }
        input::placeholder {
            @include regular-font;
            color: $dark-gray;
        }
    }
    .car-image {
        margin-bottom: 40px;
        .car-images {
            padding: 10px;
            width: 100%;
            height: 264px;
            @include flex-center;
            justify-content: center;
            flex-direction: column;
            cursor: pointer;
            @media screen and (min-width: 1024px) {
                padding: 30px 110px;
            }
            .upload-icon {
                width: 70px;
                height: 60px;
                margin: 0 auto;
            }
            .upload-text {
                text-align: center;

                p {
                    @include flex-center;
                    gap: 8px;
                    font-family: 'Sofia-Regular';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 140%;
                    @include flex-center;
                    text-align: center;
                    color: $medium-black;
                    margin-bottom: 8px;
                    margin-top: 10px;
                    a {
                        color: $main-blue;
                    }
                    label {
                        color: $main-blue;
                        padding: 0.5rem;
                        cursor: pointer;
                    }
                }
                .upload__mobile {
                    justify-content: center;
                    text-align: center;
                }
                .file-format-container {
                    display: flex;
                    align-items: center;
                }
                .file-format {
                    font-family: 'Sofia-Regular';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 140%;
                    text-align: center;
                    color: $dark-gray;
                    display: flex;
                    justify-content: center;
                }
                .drag {
                    font-size: 16px;
                }
                .browse {
                    color: $main-blue;
                }
            }
        }
        .btns-container {
            @include flex-center;
            gap: 15px;
            .form-btn {
                @include flex-center;
                justify-content: center;
                padding: 8px 16px;
                gap: 10px;
                width: 120px;
                height: 50px;
                border-radius: 5px;
                @include regular-font;
                cursor: pointer;
                @media screen and (max-width: 767px) {
                    width: 50%;
                }
                &.send {
                    background: #175adc;
                    color: #ffffff;
                }
                &.disable {
                    background-color: $dark-gray;
                    cursor: no-drop;
                    &:hover {
                        background: $light-black;
                        box-shadow: 0px 5px 10px rgba(23, 90, 220, 0.2);
                    }
                }
                &.cancel {
                    border: 1px solid #175adc;
                    color: #175adc;
                    background-color: #ffffff;
                    &.cancel:hover {
                        background: #dee6f7;
                    }
                }
            }
        }
    }
}
