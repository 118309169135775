.specifications {
    width: 80%;
    margin: auto;
    @include flex-center;
    flex-direction: column;
    margin-top: 80px;
    @media screen and (min-width: 1200px) {
        width: 69.2%;
    }
    @media screen and (min-width: 1440px) {
        max-width: 1200px;
    }
    .sec-seperator {
        height: 4px;
    }
    .specifications-container {
        @include flex-center;
        justify-content: center;
        flex-wrap: wrap;
        box-sizing: content-box;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        @media screen and (min-width: 768px) {
            flex-direction: row;
            gap: 24px;
            align-items: flex-start;
        }
        .performance-economy {
            display: flex;
            flex-direction: column;
            width: 100%;
            @media screen and (min-width: 768px) {
                width: calc((100% - 24px) / 2);
            }

            .specification-card-container {
                width: 100%;
                margin-bottom: 16px;
            }
        }
    }
}
