.promotions-container {
    background-color: #fff;
    padding: 40px 0;
    .promotions {
        @include flex-center;
        flex-direction: column;
        margin: auto;
        @media screen and (min-width: 1024px) {
            flex-direction: row;
        }
        .promotions-left {
            margin-right: 12px;
            // margin-bottom: 24px;
            @media screen and (min-width: 1024px) {
                width: 50%;
                max-width: 295px;
            }
            .title {
                white-space: normal;
                word-wrap: break-word;
                margin-bottom: 6px;
            }
            &-imageContainer {
                width: 100%;
                height: 90px;
                overflow: hidden;
                margin-top: 16px;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                cursor: pointer;
                transform: scale(1.5);
            }
        }
        .promotions-right {
            display: flex;
            overflow-x: hidden;
            width: 100%;
            .carousel-item {
                .carousel-image {
                    width: 100%;
                    border-radius: 5px;
                    margin-bottom: 12px;
                    height: 44vw;
                    @media screen and (min-width: 768px) {
                        height: 30vw;
                    }
                    @media screen and (min-width: 1024px) {
                        height: 12.5vw;
                    }
                    @media screen and (min-width: 1440px) {
                    }
                    img {
                        object-fit: cover;
                        border-radius: 5px;
                    }
                }
                .carousel-title {
                    @include regular-font;
                    color: #091731;
                    b {
                        font-weight: 700;
                    }
                }
            }
        }
        .promotions-right::-webkit-scrollbar {
            display: none;
        }
    }
}
