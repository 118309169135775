.recently-added-container {
    background-color: $light-white;

    .recently-added {
        margin: auto;
        padding-top: 40px !important;
        padding-bottom: 40px !important;
        position: relative;

        .recently-header {
            margin-bottom: 32px;
        }
        .recent-card__holder {
            @include flex-center;
            flex-wrap: wrap;
            gap: 20px;
            .card-holder {
                width: 100%;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .recently-added-container {
        .recently-added {
            padding-top: 60px !important;
            padding-bottom: 60px !important;
            .recent-card__holder {
                .card-holder {
                    width: calc((100% - 20px) / 2);
                }
            }
        }
    }
}
@media screen and (min-width: 1024px) {
    .recently-added-container {
        .recently-added {
            .recent-card__holder {
                .card-holder {
                    width: calc((100% - 40px) / 3);
                }
            }
        }
    }
}
