.questionnaire-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px 0 40px;
    position: relative;
    @media screen and (min-width: 768px) {
        padding-top: 60px;
    }
    .title {
        margin-bottom: 12px !important;
    }
    .subtitle {
        width: 90%;
        margin-bottom: 16px;
        @media screen and (min-width: 1024px) {
            width: 60%;
            text-align: start;
            margin-left: 0;
            margin-right: 0;
        }
    }
    .ask-question {
        width: auto !important;
        min-width: unset;
    }
    .questionnaire-seperator {
        margin-inline: auto;
        @media screen and (min-width: 768px) {
            margin-inline: 0;
        }
    }
    .questionnaire-image {
        display: none;
        width: 459px;
        height: 771px;
        @media screen and (min-width: 1024px) {
            display: inline-block;
        }
    }
    .questionnaire {
        width: 90%;
        margin: 0 auto;
        // position: absolute;
        margin-block: 30px;
        right: 0;
        @media screen and (min-width: 768px) {
            @include flex-center;
            flex-direction: column;
            width: 90%;
        }
        @media screen and (min-width: 1024px) {
            margin-block: 60px;
            width: 55%;
            align-items: flex-start;
            padding-right: 60px;
            margin-inline: 0;
        }
        @media screen and (min-width: 1440px) {
            padding-right: 120px;
            max-width: 1200px;
        }

        .ant-collapse {
            display: flex;
            flex-direction: column;
            gap: 16px;
            border: none;
            margin-top: 24px;
            width: 100%;
            .ant-collapse-item {
                border-radius: 5px;
                background-color: $white;
                border: 1px solid #e0e0e0;
                .accordian-btn-minus {
                    background-color: $main-blue;
                    width: 40px;
                    height: 40px;
                    border-radius: 8px;
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                    right: 24px !important;
                }
                .accordian-btn-plus {
                    width: 40px;
                    height: 40px;
                    border-radius: 8px;
                    display: flex !important;
                    align-items: center;
                    justify-content: center;
                    right: 24px !important;
                    background-color: #fafafa;
                }
                .ant-collapse-header {
                    border: none;
                    padding: 22px 46px 22px 24px !important;
                    font-family: 'Sofia-Regular';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 140%;
                    color: $medium-black;
                    word-wrap: break-word;
                    .ant-collapse-header-text {
                        flex: unset !important;
                        word-wrap: break-word;
                        width: 95%;
                    }
                }
                .ant-collapse-content {
                    border: none;
                    @include regular-font;
                    color: #8b8b8b;
                    word-wrap: break-word;
                    .ant-collapse-content-box {
                        padding: 10px 24px;
                    }
                }
            }
            .ant-collapse-item-active {
                .ant-collapse-header {
                    font-size: 20px;
                    font-weight: 700;
                }
            }
        }
        .question-btn {
            min-width: 200px;
            @media screen and (min-width: 768px) {
                left: 0;
                right: unset;
            }
        }
    }
}

.question-modal {
    background: #ffffff;
    border-radius: 10px;
    width: 90% !important;
    @media screen and (min-width: 768px) {
        width: 590px !important;
    }
    .ant-modal-content {
        padding: 24px !important;
        border-radius: 10px;
        .ant-modal-body {
            padding: 0;
            .sec-question {
                width: 80px;
                margin-inline: 0;
                @media screen and (min-width: 1024px) {
                    width: 80px !important;
                }
            }
            .question-title {
                font-size: 20px !important;
                text-align: left;
                margin-bottom: 16px;
                @media screen and (min-width: 768px) {
                    margin-bottom: 24px;
                }
            }
            .question-form {
                @include flex-center;
                flex-direction: column;
                gap: 16px;
                margin-bottom: 16px;
                textarea {
                    @include input-font;
                    outline: none;
                    width: 100%;
                    height: 116px;
                    background-color: $extra-white;
                    border: 1px solid $medium-grey;
                    border-radius: 5px;
                    padding: 8px 12px;
                }
                textarea::placeholder {
                    @include input-placeholder;
                }
                .questionnaire-number {
                    width: 100%;
                    border-radius: 5px;
                    height: 50px;
                    gap: 5px;
                    .phone-input {
                        width: 100%;
                    }
                    .icon {
                        margin-top: 5px;
                        transform: rotate(5deg);
                    }
                    input {
                        outline: none;
                        width: 100%;
                        @include input-font;
                    }
                    input::placeholder {
                        @include input-placeholder;
                    }
                }
                .question-footer {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin-top: 10px;
                    p {
                        font-family: 'Sofia-Regular';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 140%;
                        color: $light-black;
                        width: 55%;
                    }
                    .terms-condt {
                        width: 71%;
                    }
                    .bottom-btn {
                        width: 123px;
                        height: 45px;
                        min-width: unset;
                    }
                }
            }
        }
        .ant-modal-footer {
            display: none;
        }
    }
}
