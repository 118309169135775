.why-finance-container {
    background-color: $light-white;
    padding: 60px 0;
    .why-finance {
        width: 90%;
        margin: 0 auto;
        @include flex-center;
        flex-direction: column;
        @media screen and (min-width: 1440px) {
            max-width: 1200px;
            justify-content: space-between;
        }
        .title {
            margin-bottom: 8px;
            @media screen and (min-width: 1024px) {
                font-size: 31px;
            }
        }
        .subtitle {
            text-align: center;
            margin-bottom: 24px;
            width: 100%;
            @media screen and (min-width: 1024px) {
                width: 41%;
            }
        }
        .why-finance-units {
            display: flex;
            justify-content: center;
            width: 100%;
            @media screen and (min-width: 1024px) {
                width: 74%;
            }
            @media screen and (min-width: 1200px) {
                width: 80.25%;
            }
            @media screen and (min-width: 1440px) {
                width: 100%;
                max-width: 1200px;
                justify-content: space-between;
            }
            .swiper-wrapper {
                @media screen and (min-width: 1200px) {
                    justify-content: center;
                }
                .swiper-slide {
                    background-color: transparent;
                    @media screen and (max-width: 768px) {
                        width: 48% !important;
                    }
                    @media screen and (min-width: 1024px) {
                        width: 33%;
                    }
                    @media screen and (min-width: 1200px) {
                        width: 100%;
                    }
                }
            }
        }
    }
}
.why-finance-unit {
    @include flex-center;
    flex-direction: column;
    padding-top: 8px;
    .why-finance-image {
        width: 58px;
        height: 48px;
        margin-bottom: 14px;
    }
    .finance-title {
        font-family: 'Sofia-Regular';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: $main-dark-blue;
        margin-bottom: 12px;
        @media screen and (min-width: 1024px) {
            font-size: 20px;
        }
    }
    .why-finance-desc {
        font-family: 'Sofia-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        text-align: center;
        color: $dark-gray;
        width: 100%;
        @media screen and (min-width: 1024px) {
            font-size: 16px;
        }
        @media screen and (min-width: 1200px) {
            width: 70%;
        }
    }
}
