.sell-page {
    .sell-hero {
        width: 100%;
        position: relative;
        z-index: 10;
        @include flex-center;
        flex-direction: column;
        @media screen and (min-width: 768px) {
            background: url('../../../public/images/sellPage.svg') no-repeat;
            height: 400px;
            background-size: cover;
            z-index: -1;
        }
        @media screen and (min-width: 1024px) {
            z-index: 1;
        }
        .desktop-hide {
            margin-top: 24px;
            @media screen and (min-width: 768px) {
                display: none;
            }
        }
        .mobile-hide {
            display: none;
            margin-top: 86px;
            @media screen and (min-width: 768px) {
                display: inline-block;
            }
        }
        .sell-title {
            font-family: 'Sofia-Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 140%;
            text-align: center;
            margin: 0 auto;
            width: 78%;
            color: $main-dark-blue;
            @media screen and (min-width: 768px) {
                color: $white;
                font-size: 39px;
            }
            @media screen and (min-width: 1024px) {
                width: 50%;
                color: $white;
                font-size: 39px;
            }
            @media screen and (min-width: 1280px) {
                width: 35%;
                color: $white;
                font-size: 39px;
            }
        }
        .fr {
            width: 100%;
        }
        .sell-subtitle {
            font-family: 'Sofia-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 140%;
            text-align: center;
            width: 90%;
            margin: 0 auto;
            color: $light-black;
            margin-bottom: 24px;
            @media screen and (min-width: 768px) {
                color: $white;
                width: 50%;
                font-size: 16px;
                margin-bottom: 0;
            }
            @media screen and (min-width: 1200px) {
                width: 36%;
            }
            @media screen and (min-width: 1440px) {
                width: 34%;
            }
        }
    }
    .fre {
        height: 300px;
    }
    .sell-vector {
        position: relative;
        height: 125vh;

        @media screen and (min-width: 1024px) {
            height: 110vh;
        }
        .vector-container {
            display: none;
            width: 60%;
            transform: translate(20%, -25%);
            margin: auto;
            margin-inline: auto;
            z-index: -10;
            @media screen and (min-width: 1024px) {
                display: inline-block;
                transform: translate(40%, -25%);
                height: 140vh;
            }
            img {
                object-fit: fill;
            }
        }
    }
}
