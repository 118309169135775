.blog-details {
    .type-share {
        @media screen and (min-width: 1024px) {
            margin: 0 auto;
            width: 70%;
        }
        @media screen and (min-width: 1200px) {
            @include flex-center;
            justify-content: space-between;
            width: 72%;
        }
    }
}
