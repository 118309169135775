.feature-card {
    @include flex-center;
    justify-content: space-between;
    background-color: $light-white;
    padding: 13px 12px;
    border-radius: 5px;
    .feature-card-header {
        @include flex-center;
        gap: 10px;
    }
    .feature-title {
        @include regular-font;
        color: $black;
    }
    .feature-verified {
        @include flex-center;
        gap: 14px;
        .number {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            @include flex-center;
            justify-content: center;
            text-align: center;
            font-family: Sofia-Regular;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            color: $white;
            background-color: #ffb040;
        }
    }
}
.hide-card {
    display: none;
}
