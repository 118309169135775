.error {
    color: red;
}
.phone-input {
    height: 50px;
    background-color: $extra-white;
    border-radius: 5px;
    display: flex !important;
    border: 1px solid $medium-grey;
    overflow: hidden;
    align-items: center;
    @include regular-font;
    color: $medium-grey;
    .phone-select {
        width: 128px !important;
        height: 100% !important;
        background-color: $extra-white;
        max-height: 240px;
        .ant-select-selector {
            height: 100% !important;
            background-color: transparent !important;
            border: none !important;
            border-radius: 0;
            .ant-select-selection-search {
                input {
                    height: 100%;
                }
            }
        }

        .ant-select-selection-item {
            display: flex;
        }
    }

    .ant-select-focused {
        .ant-select-selector {
            box-shadow: none !important;
        }
    }

    .phone-label {
        @include flex-center;
        margin-right: 5px;
        img {
            max-height: 15px;
            width: 22px;
            max-width: 22px;
            margin-right: 8px;
            object-fit: contain;
        }

        span {
            font-family: 'Sofia-Regular';
            font-size: 16px;
            @include input-font;
        }
    }

    .input-divider {
        width: 1px;
        height: 100%;
        height: 24px;
        background-color: $medium-grey;
    }

    .phone-inputbox {
        padding: 14px 8px;
        @include input-font;
        border: none !important;
        background-color: $extra-white;
        cursor: text;
    }

    .ant-input:focus {
        box-shadow: none !important;
    }
}
.phone-input-dropdown {
    max-height: 240px;
}

.phone-option {
    @include flex-center;
    font-family: 'Sofia-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    border: none !important;
    img {
        max-height: 15px;
        width: 22px;
        max-width: 22px;
        margin-right: 8px;
        object-fit: contain;
    }
}
.ant-input-group.ant-input-group-compact > *:not(:last-child) {
    margin-right: 0 !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: transparent !important;
    color: $main-blue !important;
    .phone-input {
        color: $main-blue !important;
    }
}

.dropdown-form {
    width: 94%;
    height: 40px;
    margin-inline: auto !important;
    margin-bottom: 16px !important;
    .dropdown-input {
        @include flex-center;

        justify-content: center;
        flex-direction: row;
        background-color: $extra-white;
        border: 1px solid $medium-grey;
        border-radius: 5px;
        height: 100%;
        padding-left: 7px;
        margin-bottom: 16px;
        input {
            background-color: $extra-white;
            padding-left: 5.6px !important;
            font-size: 13px !important;
        }
        input::placeholder {
            columns: $medium-grey;
            font-size: 15px !important;
            padding: 0 !important;
            background-color: $extra-white;
        }
    }
}
