.bank-details {
    margin-bottom: 104px;
    .bank-details-sep {
        margin-inline: 0;
    }
    .bank-details-title {
        text-align: start;
        margin-bottom: 24px;
    }
    .bank-details-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-bottom: 40px;
    }
}
.bank-detail {
    @include flex-center;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 16px;
    gap: 24px;
    background: $white;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    @media screen and (min-width: 1024px) {
        flex-direction: row;
        height: 82px;
    }
    .bank-logo {
        width: 100%;
        height: 46px;
        @media screen and (min-width: 768px) {
            width: 132px;
        }
        @media screen and (min-width: 1024px) {
            width: 132px;
        }
        img {
            object-fit: contain;
        }
    }
    .categories-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .categories {
        display: flex;
        flex-direction: column;
        .category {
            font-family: 'Sofia-Regular';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 140%;
            @include flex-center;
            text-align: center;
            color: #595959;
            margin-bottom: 4px;
            @media screen and (min-width: 768px) {
                font-size: 16px;
            }
        }
        .value {
            font-family: 'Sofia-Bold';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 140%;
            @include flex-center;
            text-align: center;
            color: $main-dark-blue;
        }
    }
    .border {
        padding-right: 8px;
        border-right: 1px solid #e9edf1;
        @media screen and (min-width: 1024px) {
            padding-right: 35px;
        }
        @media screen and (min-width: 1440px) {
            padding-right: 46px;
        }
    }
    .bottom-btn {
        max-width: 150px;
    }
}
