.carIssue {
    width: 80%;
    margin: auto;
    @include flex-center;
    flex-direction: column;
    background-color: $white;
    margin-top: 93px;
    @media screen and (min-width: 1200px) {
        width: 69.2%;
    }
    @media screen and (min-width: 1440px) {
        max-width: 1200px;
    }
    &-container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 24px;
        column-gap: 24px;
        place-items: center;
    }
    &-item {
        background-color: #F5F8FF;
        border-radius: 8px;
        width: 100%;
        height: 359px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        & > img {
            width: 100%;
            object-fit: cover;
            flex-grow: 1;
            border-radius: 4px;
        }
        & > p {
            align-self: flex-start;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            color: #454545;
        }
    }
}
