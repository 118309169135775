.no-car-found {
    @include flex-center;
    justify-content: center;
    .form-container {
        max-width: 500px;
        .title {
            text-align: center;
        }
        .image-container {
            width: 150px;
            height: 97px;
            margin: 2rem auto;
        }
        form {
            margin-top: 1rem;
            .phone-input {
                label {
                    padding-left: 1em;
                    margin-right: 1rem;
                }
                input {
                    width: 100%;
                    height: 100%;
                    @include input-font;
                    color: $dark-gray;
                    background-color: $extra-white;
                    @media screen and (min-width: 768px) {
                        font-size: 16px;
                    }
                }
                input::placeholder {
                    background: $extra-white;
                    @include input-font;
                    color: $medium-grey;
                }
            }
            .phone-number {
                margin: 1.3rem 0;
            }
            .terms-condt-listing {
                text-align: center;
            }
            .bottom-btn {
                margin: 0 auto;
                width: 200px;
            }
        }
    }
}
