.serviceHistory {
    width: 80%;
    margin: auto;
    @include flex-center;
    flex-direction: column;
    background-color: $white;
    margin-top: 93px;
    @media screen and (min-width: 1200px) {
        width: 69.2%;
    }
    @media screen and (min-width: 1440px) {
        max-width: 1200px;
    }
    .history-container {
        width: 100%;
        .ant-collapse {
            display: flex;
            flex-direction: column;
            gap: 16px;
            border: none;
            width: 100%;
            background-color: transparent;
            .ant-collapse-item {
                border-radius: 5px;
                background-color: $light-white !important;
                border: none;
                width: 100%;
                .ant-collapse-header {
                    @include regular-font;
                    color: $main-dark-blue;
                    background-color: $light-white !important;
                    .ant-collapse-extra {
                        .latest-service {
                            @include regular-font;
                            color: $medium-black;
                            text-transform: capitalize;
                        }
                    }
                }
                .ant-collapse-content {
                    background-color: $light-white !important;
                    border: none;
                    .ant-collapse-content-box {
                        .service {
                            @include flex-center;
                            justify-content: space-between;
                            .service-title {
                                font-family: 'Sofia-Regular';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 140%;
                                color: #454545;
                            }
                            .service-value {
                                font-family: 'Sofia-Regular';
                                font-style: normal;
                                font-weight: 700;
                                font-size: 13px;
                                line-height: 140%;
                                text-align: right;
                                color: $main-dark-blue;
                            }
                        }
                        .service:first-child {
                            margin-bottom: 12px;
                        }
                    }
                }
            }
        }
    }
}
