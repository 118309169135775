.load-more-btn {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    cursor: pointer;

    .load-more {
        align-items: center;
        padding: 8px 16px;
        gap: 10px;
        background-color: $white;
        height: 50px;
        @include regular-font;
        color: #175adc;
        border: 1px solid $main-blue;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
            background: #dee6f7;
            border: 1px solid #175adc;
        }
    }
    .width {
        width: 100%;
        height: unset;
    }
}
