.find-car-price-form-container {
    width: 90%;
    margin: 0 auto;
    background-color: $white;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    z-index: 20;
    padding: 0 32px 32px 32px;
    @media screen and (min-width: 768px) {
        width: 80%;
        margin-inline: auto;
        transform: translate(0, -68px);
    }
    @media screen and (min-width: 1024px) {
        width: 70%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -68px);
    }
    @media screen and (min-width: 1200px) {
        width: 48.62%;
    }
    @media screen and (min-width: 1440px) {
        max-width: 1200px;
    }
    .bottom-btn {
        margin: auto;
    }
    .bottom-btn:disabled {
        cursor: no-drop;
    }
    .find-car-price-form-top {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 68px;
        & > h6 {
            font-family: Sofia-Bold;
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            color: #091731;
        }
    }
    .find-car-price-form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 24px;
        @media screen and (min-width: 768px) {
            flex-direction: row;
        }
    }
    .find-car-price-form-bottom {
        display: flex;
        flex-direction: column;
        gap: 24px;
        margin-top: 24px;
    }
}
.find-car-price-form-field {
    width: 100%;
    &:first-child, &:nth-child(2) {
        // for country and year dropdown
        width: 100%;
    }
    @media screen and (min-width: 768px) {
        width: calc(50% - 12px);
    }
}
.find-car-price-form-input {
    @include flex-center;
    width: 100%;
    height: 48px;
    border-radius: 5px;
    padding: 14px 10px;
    gap: 10px;
    background-color: $extra-white;
    border: 1px solid $medium-grey !important;
    @media screen and (min-width: 768px) {
        width: calc(50% - 12px);
    }
    .selects-prefixicon {
        z-index: 10;
        width: 25px;
        height: 22px;
    }
    input {
        width: 100%;
        background-color: $extra-white;
        @include input-font;
        text-transform: capitalize;
    }
    input::placeholder {
        background-color: $extra-white;
        @include input-placeholder;
        color: $medium-grey;
    }
}
.ant-modal {
    padding-bottom: 0 !important;
}
