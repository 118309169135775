@font-face {
    font-family: Sofia-Bold;
    src: url(../../fonts/SofiaProBoldAz.otf);
}
@font-face {
    font-family: Sofia-Regular;
    src: url(../../fonts/SofiaProRegularAz.otf);
}
@font-face {
    font-family: Sofia-Pro;
    src: url(../../fonts/Sofia-Pro.otf);
}
