.summary {
    width: 80%;
    margin: auto;
    @include flex-center;
    flex-direction: column;
    margin-top: 80px;
    overflow-x: hidden;
    @media screen and (min-width: 1200px) {
        width: 69.2%;
    }
    @media screen and (min-width: 1440px) {
        max-width: 1200px;
    }
    .sec-seperator {
        height: 4px;
    }
    .summary-container {
        @include flex-center;
        flex-wrap: wrap;
        width: 100%;
        .summary-card-container {
            width: 100%;
            margin-bottom: 16px;

            @media screen and (min-width: 768px) {
                width: calc((100% - 24px) / 2);
            }
        }
        .summary-card-container:nth-child(odd) {
            @media screen and (min-width: 768px) {
                margin-right: 24px;
            }
        }
    }
}
.margin-top {
    margin-bottom: 200px;
}
