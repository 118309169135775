.features {
    width: 80%;
    margin: auto;
    @include flex-center;
    flex-direction: column;
    margin-top: 70px;
    margin-bottom: 40px;
    overflow-x: hidden;
    @media screen and (min-width: 1200px) {
        width: 69.2%;
    }
    @media screen and (min-width: 1440px) {
        max-width: 1200px;
    }
    .sec-seperator {
        height: 4px;
    }
    .features-container {
        width: 100%;
        .features-categories {
            .features-category {
                display: flex;
                flex-wrap: wrap;
                gap: 24px;
                width: 100%;
                margin-bottom: 16px;
                @media screen and (min-width: 1024px) {
                    gap: 16px;
                }
                .show-card {
                    @media screen and (min-width: 768px) {
                        width: calc((100% - 24px) / 2);
                    }
                    @media screen and (min-width: 1024px) {
                        width: calc((100% - 48px) / 4);
                    }
                }
                .hide-card {
                    display: none;
                }
                .category-card {
                    width: 100%;
                    opacity: 1;
                    transition: all 0.5s ease-out;
                    &.faded {
                        opacity: 0.5;
                    }
                    @media screen and (min-width: 768px) {
                        width: calc((100% - 24px) / 2);
                    }
                    @media screen and (min-width: 1024px) {
                        width: calc((100% - 48px) / 4);
                    }
                }
            }
        }
    }
    .show-more {
        @include regular-font;
        color: $main-blue;
        flex-direction: row;
        @include flex-center;
        padding: 8px 16px;
        gap: 10px;
        background: $white;
        border: 1px solid $main-blue;
        border-radius: 5px;
        height: 45px;
    }
}
