.buy-sell {
    width: 100%;
    margin: 0 auto;
    @include flex-center;
    flex-direction: column;
    justify-content: center;
    padding: 80px 0;
    background: linear-gradient(
            0deg,
            rgba(9, 23, 49, 0.8),
            rgba(9, 23, 49, 0.8)
        ),
        url('../../../public/svgs/buysell.webp') no-repeat;
    background-size: cover;
    height: 339px;
    .title {
        color: white;
        margin-bottom: 24px;
    }
    .subtitle {
        text-align: center;
        color: $white;
        margin-bottom: 24px;
    }
    .buy-sell-btns {
        @include flex-center;
        justify-content: center;
        gap: 22px;
        width: 100%;
        .bottom-btn {
            width: calc((100% - 22px) / 2);
            height: 50px;
            min-width: unset;

            @media screen and (min-width: 768px) {
                width: 192px;
            }
        }
        .bottom-btn:first-child {
            color: $main-dark-blue;
            background-color: $white;
            &:hover {
                background: #dee6f7;
            }
        }
    }
}
