.account-mobile {
  .account-page {
    .account {
      .user-info {
        display: inline-flex;
        gap: 16px;
        margin-bottom: 30px;
      }
      .account-content {
        .account-tab {
          .ant-tabs-nav {
            .ant-tabs-nav-wrap {
              .ant-tabs-nav-list {
                display: inline-flex;
                .ant-tabs-tab {
                  width: 100% !important;
                  padding-inline: 0 !important;
                  .ant-tabs-tab-btn {
                    width: 100% !important;
                    justify-content: space-between;
                  }
                }
                .ant-tabs-tab::after {
                  border: none !important;
                  width: 0 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
