.inspection-report {
    margin-block: 60px;
    width: 80%;
    margin-inline: auto;
    @media screen and (min-width: 1200px) {
        width: 69.2%;
    }
    .sec-seperator {
        margin-inline: auto;
    }
    .title {
        text-align: center;
        margin-bottom: 30px;
    }
    .feature-icon {
        width: 24px;
        height: 24px;
    }
    .inspection-subtitle {
        @include flex-center;
        justify-content: center;
        margin-bottom: 24px;
        gap: 10px;

        p {
            @include regular-font;
            color: #000000;
            b {
                font-family: Sofia-Bold;
                font-weight: 700;
                font-style: normal;
                font-size: 16px;
                line-height: 140%;
            }
        }
    }
    .feature-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        flex-direction: column;
        width: 100%;
        @media screen and (min-width: 768px) {
            flex-direction: row;
        }
        .featured-card-container {
            width: 100%;
            @media screen and (min-width: 768px) {
                width: calc(50% - 12px);
                flex-direction: row;
            }
        }
    }
}

.show-feature {
    display: none;
    width: 100%;
    ul {
        list-style: none;
        width: 100%;
        padding-left: 12px;

        li {
            padding-block: 20px;
            @include flex-center;
            justify-content: space-between;
            @include regular-font;
            color: $black;
            padding-right: 12px;
            border-bottom: 1px solid #b3b3b3;
            .list-left {
                .note-title {
                    font-family: 'Sofia-Bold';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 140%;
                    color: $black;
                    text-transform: capitalize;
                }
                .caption {
                    @include regular-font;
                    color: $light-black;
                    margin-top: 14px;
                    text-transform: capitalize;
                }
            }
            .list-right {
                @include flex-center;
                gap: 10px;
                .show-photo {
                    color: $medium-grey;
                    font-size: 13px;
                    text-decoration: underline;
                }
                .photo-preview {
                    display: none;
                }
            }
        }

        li:last-child {
            border-bottom: 1px solid transparent;
        }

        .image-container {
            width: 120px;
            height: 73px;
            position: relative;

            border-radius: 5px;
            .photo-container {
                width: 120px;
                height: 73px;
            }
            .note-number {
                position: absolute;
                top: 0;
                left: 0;
                width: 120px;
                height: 73px;
                border-radius: 5px;
                background: linear-gradient(
                    0deg,
                    rgba(23, 90, 220, 0.4),
                    rgba(23, 90, 220, 0.4)
                );
                text-align: center;
                display: grid;
                place-items: center;
                .note {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    @include flex-center;
                    justify-content: center;
                    background-color: #b3bfd6;
                    font-family: 'Sofia-Regular';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 140%;
                    color: #ffffff;
                }
            }
        }
    }
}
.show-drops {
    display: inline-block;
}
