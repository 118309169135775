// gray-scale
$black: #212121;
$medium-black: #454545;
$light-black: #595959;
$dark-gray: #8b8b8b;
$light-medium-b2: #b2b2b2;
$grey: #e0e0e0;
$medium-grey: #b3b3b3;

$medium-white: #f2f2f2;
$light-white: #fafafa;
$white: #ffffff;
$extra-white: #f5f8ff;

// main-blue
$light-blue: #6690e4;
$main-blue: #175adc;
$dark-blue: #07348d;

// dark-blue
$main-dark-blue: #091731;
$dark-blue-tint1: #1f335a;
$dark-blue-tint2: #4359b3;
$dark-blue-tint3: #74bbac;
$dark-blue-tint4: #b3bfd6;
$dark-blue-tint5: #d7deea;

// others
$succes: #091731;
$warning: #1f335a;
$error: #4359b3;
$info: #74bbac;
$success: #009e60;
$input-background: #fcfdff;
$error-color: #ff4d4f;
