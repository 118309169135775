.download-app-container {
    padding: 0 100px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 48px;
    @media screen and (max-width: 768px) {
        padding: 40px 40px !important;
        align-items: center;
        justify-content: center;
    }

    .content-div {
        font-family: 'Sofia-Regular';
        @media screen and (max-width: 768px) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        h1 {
            font-size: 32px;
            font-weight: 700;
            color: $main-dark-blue;
            @media screen and (max-width: 768px) {
                font-size: 24px;
            }
        }
        p {
            font-size: 16px;
            color: $light-black;
            margin: 8px 0 24px 0;
            @media screen and (max-width: 768px) {
                text-align: center;
            }
        }
        .playstore-img {
            img {
                width: 180px;
                height: auto;
            }
        }
    }
    .image-div {
        img {
            height: auto;
            width: auto;
            @media screen and (max-width: 1200px) {
                width: 300px;
            }
        }
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
}
.filterCarsFooter {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
    font-family: 'Sofia-Regular';
    @media screen and (min-width: 768px) {
        padding: 40px 30px !important;
    }
    p {
        display: flex;
        align-items: center;
        gap: 6px;
        color: $light-black;
        cursor: pointer;
        &:hover {
            color: $dark-blue;
            svg {
                color: $dark-blue;
            }
        }
        svg {
            color: $main-blue;
        }
    }
}
