.certification {
    margin: 0 auto;
    padding-block: 40px !important;

    @media screen and (min-width: 768px) {
        padding-top: 60px !important;
        padding-bottom: 80px !important;
    }
    .certification-container {
        display: flex;
        gap: 24px;
        justify-content: center;
        @media screen and (min-width: 768px) {
            display: flex;
            gap: 24px;
            justify-content: space-between;
        }
        .certi {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
    }
    .title {
        @media screen and (min-width: 768px) {
            margin-bottom: 24px;
        }
    }
    .subtitle {
        margin-bottom: 8px;
        color: $light-black;
        width: 100%;
    }
    .mobile-hide {
        display: none;
        width: 100%;
        @media screen and (min-width: 768px) {
            display: inline-block;
        }
        .desktop-hide {
            @media screen and (min-width: 768px) {
                display: none;
            }
        }
    }
    .ant-select-selection-search-input .ant-modal {
        .certification-form {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
    }
    .bottom-btn {
        width: auto;

        &.mobile {
            margin-inline: auto;
            @media screen and (min-width: 768px) {
                display: none;
            }
        }
        &.desktop {
            display: none;
            @media screen and (min-width: 768px) {
                display: inline-block;
            }
        }
    }
    .image-container {
        display: none;
        @media screen and (min-width: 768px) {
            display: inline-block;
        }
        img {
            width: 100%;
            object-fit: contain;
        }
    }
}
.certification-form {
    margin-bottom: 26px;
    width: 100%;
    @include flex-center;
    flex-direction: column;
    gap: 25px;
    @media screen and (min-width: 1024px) {
        margin-bottom: 32px;
        row-gap: 22px;
    }
    .selects-container {
        width: 100%;
        @media screen and (min-width: 768px) {
            width: calc((100% - 24px) / 2);
            max-width: calc((100% - 24px) / 2);
            min-width: calc((100% - 24px) / 2);
        }
    }
    .brand {
        max-width: 100%;
        width: 100%;
    }
    .certification-top {
        @include flex-center;
        gap: 25px;
        flex-direction: column;
        width: 100%;
        @media screen and (min-width: 768px) {
            justify-content: space-between;
            flex-direction: row;
            gap: 24px;
        }
    }
    .certification-bottom {
        width: 100%;
        @include flex-center;
        flex-direction: column;
        gap: 16px;
        @media screen and (min-width: 768px) {
            justify-content: space-between;
            flex-direction: row;
            gap: 24px;
        }
    }
}
.certification-modal-container {
    .ant-modal-content {
        padding: 34px 16px !important;
        .ant-modal-body {
            .certification-form {
                margin-bottom: 0;
            }
        }
        .desktop-hide {
            .left-align {
                margin-inline: auto;
            }
            .title {
                margin-bottom: 16px;
                font-size: 16px !important;
            }
            .subtitle {
                display: none;
            }
        }
    }
}

.cert-details {
    position: relative;
    width: 100%;
    margin-top: 12px;
}

.certification-input {
    @include flex-center;
    width: 100% !important;
    height: 48px !important;
    border-radius: 5px;
    padding: 14px 10px;
    gap: 10px;
    background-color: $extra-white;
    border: 1px solid $medium-grey !important;

    .selects-prefixicon {
        z-index: 10;
        width: 25px;
        height: 22px;
    }

    input {
        width: 100%;
        background-color: $extra-white;
        @include input-font;
        text-transform: capitalize;
    }

    input::placeholder {
        background-color: $extra-white;
        @include input-placeholder;
        color: $medium-grey;
    }
}

.cert-submit {
    width: 100%;
    margin-top: -15px;
    @media screen and (min-width: 768px) {
        @include flex-center;
        justify-content: space-between;
    }
    span {
        @include regular-font;
        color: $light-black;
        margin-right: 64px;
        .termandpolicy {
            text-decoration: underline;
            color: $main-blue;
            font-family: 'Sofia-Regular';
            @media (max-width: 720px) {
                display: inline;
            }
        }
    }
    .send {
        @media screen and (max-width: 767px) {
            margin-top: 10px;
            margin-inline: auto;
        }
        @media screen and (min-width: 768px) {
            min-width: unset;
        }
    }
}

.error-label {
    font-size: 15px;
    font-family: 'Sofia-Regular';
    color: $error-color;
    margin-left: 3px;
    &.fade-in {
        animation: inAnimation 250ms ease-in;
    }
    &.fade-out {
        animation: outAnimation 270ms ease-out;
        animation-fill-mode: forwards;
    }
}
.border-error {
    border: 1px solid $error-color !important;
}
