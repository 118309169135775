.service {
    background-color: $extra-white;
    padding-block: 60px !important;
    .center {
        margin: 0 auto;
    }
    .margin-bottom {
        margin-bottom: 12px;
    }
    .subtitle {
        text-align: center;
    }
    .title {
        margin-bottom: 8px;
    }
    .service-container {
        display: flex;
        gap: 24px;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 48px;
    }
}
.service-unit {
    background-color: $white;
    padding: 24px;
    text-align: center;
    border-radius: 5px;
    width: 100%;
    @media screen and (min-width: 500px) {
        width: calc((100% - (24px * 4)) / 2);
    }
    @media screen and (min-width: 768px) {
        width: calc((100% - (24px * 4)) / 3);
    }
    @media screen and (min-width: 1024px) {
        width: calc((100% - (24px * 4)) / 5);
    }
    &:hover {
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    }
    .icon-container {
        width: 48px;
        height: 40px;
        margin: 0 auto;
        margin-bottom: 24px;
    }
    .title {
        font-size: 20px;
        text-align: center;
    }
    .subtitle {
        min-height: 44.8px;
    }
}
