.otpVerification {
    font-style: normal;
    font-weight: 400;
    color: #595959;

    .otp-verification-subs {
        @include regular-font;
        margin-bottom: 24px;
        color: $light-black;
    }
    .otp-inputs {
        @include flex-center;
        justify-content: space-between;
        margin-bottom: 12px;
        input {
            width: 54px;
            height: 54px;
            background: #ffffff;
            border: 0.5px solid #dddddd;
            text-align: center;
            border-radius: 5px;
            @include input-font;
            line-height: 140%;
            margin-right: 16px;
        }
    }
    .otpbutton {
        max-width: 335px;
        margin-top: 20px;
        height: 50px;
    }
    .otp-footer-info {
        margin-top: 12px;
        @include regular-font;
        span {
            display: inline;
            cursor: pointer !important;
            font-weight: 700;
            margin-left: 1px;
            color: #175adc;
        }
    }
}
