.car-overview {
    @include flex-center;

    flex-direction: column;
    margin-top: 60px;
    .overview-container {
        display: flex;
        gap: 24px;
        justify-content: center;
        flex-direction: column;
        width: 69.2%;
        margin-inline: auto;
        @media screen and (min-width: 768px) {
            flex-direction: row;
        }
        @media screen and (min-width: 1440px) {
            max-width: 1200px;
        }
        .overview-item {
            @include flex-center;
            flex-direction: column;
            background: $light-white;
            padding: 12px 16px;
            border-radius: 5px;
            gap: 16px;
            width: 100%;
            @media screen and (min-width: 768px) {
                width: calc((95.6% - 16px) / 2);
            }
            @media screen and (min-width: 1024px) {
                width: calc((93.6% - 48px) / 4);
            }
            @media screen and (min-width: 1200px) {
                width: calc((94.6% - 48px) / 4);
            }
            .overview-icon {
                width: 24px;
                height: 24px;
            }
            .overview-content {
                text-align: center;
                .overview-title {
                    @include regular-font;
                    color: $light-black;
                }
                .overview-value {
                    @include regular-font;
                    color: $main-dark-blue;
                    text-transform: capitalize;
                }
            }
        }
    }
}
