.sec-seperator {
    width: 100px;
    height: 0;
    @include flex-center;

    margin: auto;
    margin-bottom: 8px;
    .seperator-first {
        width: 50%;
        border: 2px solid $main-blue;
    }
    .seperator-second {
        width: 50%;
        border: 2px solid $main-dark-blue;
    }
}
.left-align {
    margin-inline: 0;
}
@media screen and (min-width: 768px) {
    .sec-seperator {
        width: 110px;
        margin: 0;
        margin-bottom: 12px;
    }
}
@media screen and (min-width: 1024px) {
    .sec-seperator {
        width: 130px;
    }
}
