.mySwiper {
    .swiper-button-prev {
        display: none;
        @media screen and (min-width: 1024px) {
            display: flex !important;
            align-items: center;
            color: #454545;
            background-color: #fff;
            border-radius: 20px;
            font-size: 10px !important;
            height: 40px;
            width: 40px;
            box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
        }
    }
    .swiper-button-next {
        display: none;
        @media screen and (min-width: 1024px) {
            display: flex !important;
            align-items: center;
            color: #454545;
            background-color: #fff;
            border-radius: 20px;
            font-size: 10px !important;
            height: 40px;
            width: 40px;
            box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.12);
            top: 90px;
        }
    }
    .swiper-button-next:after {
        font-size: 16px;
        font-weight: 700;
    }
    .swiper-button-prev:after {
        display: block;
        font-size: 16px;
        font-weight: 700;
    }
    .swiper-button-prev.swiper-button-disabled {
        display: none !important;
    }
    .swiper-button-next.swiper-button-disabled {
        display: none !important;
    }
}
